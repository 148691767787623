<template>
    <div class="login">
        <top title="我的" />
        <div class="mine">
            <img src="../../assets/img/logo.png" alt="">
        </div>
        <div class="mine_box">
            <div class="mine_title">我的应用</div>
            <div v-for="(item,index) in list" :key="index">
                <div class="mine_item"  @click="onClickItem(item)">
                    <div class="mine_item_left" >
                        <img :src="item.img" alt="" class="mine_img">
                        <div>{{item.name}}</div>
                    </div>
                    <van-icon name="arrow" color="#888888" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cookies from 'vue-cookies'
export default {
  data() {
    return {
        list:[
            {
                id:'1',
                img:require('../../assets/img/mine_card.png'),
                type:'/warranty_manage',
                name:'我的卡片'
            },
            {
                id:'2',
                img:require('../../assets/img/hexiao.png'),
                type:'/record_cancel',
                name:'核销记录'
            },
            {
                id:'3',
                img:require('../../assets/img/yaodian.png'),
                name:'适用门店',
                type:'/store'
            },
            {
                id:'4',
                img:require('../../assets/img/password.png'),
                name:'修改密码',
                type:'/password',
                parmas:'emendPwd'
            },
            {
                id:'5',
                img:require('../../assets/img/phone.png'),
                name:'修改手机号码',
                type:'/editPhone'
            },
            {
                id:'6',
                img:require('../../assets/img/exit.png'),
                name:'退出登录'
            }
        ]
    };
  },
  methods:{
    onClickItem(item){
        const type = localStorage.getItem('store') || '';
      if(item.id == 3 && type === '0'){
            this.$toast('请先打开定位功能')
            return
        }
        if(item.id == 6){
            this.$dialog.confirm({
                title:'提示',
                message:'是否退出当前登录账户'
            }).then(()=>{
                localStorage.removeItem('user_data')
                cookies.remove('token');
                this.$router.push({path:'/'})
            })
                return
        }
        this.$router.push({path:item.type,query:{type:item.parmas}})
    }
  }
};
</script>

<style lang="scss" scoped>
.login{
    background: #fcfcfc;
    min-height: 100vh;
    overflow-x: hidden;
}
.mine{
    width: 100%;
    height: vw(550);
    background: #019CF4 ;
    img{
        width: vw(280);
        height:vw(196);
        margin: vw(78) vw(236) 0 ;
    }
}
.mine_box{
    width: vw(702);
    height:100%;
    border-radius: vw(8);
    box-shadow: 0px 0px 10px 2px rgba(133,133,133,0.2);
    background: #fff;
    margin: vw(-170) vw(24) 0;
    padding: vw(28) vw(28) 0;
    color: #333;
    font-size: vw(28);
    box-sizing: border-box;
    .mine_title{
        font-size: vw(32);
        font-weight: 600;
        margin-bottom: vw(18);
    }
    .mine_item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        border-bottom: 1px solid #f4f4f4;
        padding: vw(36) 0;
        div{
            display: flex;
            align-items: center;
        }
    }
    .mine_img{
        width: vw(40);
        height: vw(40);
        margin-right: vw(24);
    }
}
</style>
